import { Injectable } from "@angular/core";
import {
  ToastController,
  ModalController,
  ActionSheetController,
  AlertController,
} from "@ionic/angular";
import { Router } from "@angular/router";
import { ClipboardService } from "ngx-clipboard";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  accept_policy_key = "accept";

  key_acc = "acc_no";
  key_mnemonic = "mnemonic";
  key_address = "address"

  static Mnemonic = null;
  static AccNo = null;
  static Address = null;

  constructor(
    private toast: ToastController,
    private router: Router,
    private actionSheetController: ActionSheetController,
    public modalController: ModalController,
    private alertController: AlertController,
    private _clipboardService: ClipboardService
  ) {}

  copyTextToClipboard(text: string) {
    this._clipboardService.copyFromContent(text);
  }

  async presentAlert(header: string, message: string, buttonText = "Ok") {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [buttonText],
    });
    await alert.present();
  }

  checkAddressInStorage() {
    return localStorage.getItem(this.key_address)?true:false
  }

  putUser(acc_no: Number, mnemonic: string, address: string) {
    UtilityService.Mnemonic = mnemonic;
    UtilityService.AccNo = acc_no;
    UtilityService.Address = address;

    localStorage.setItem(this.key_acc, acc_no.toString());
    mnemonic ?localStorage.setItem(this.key_mnemonic, mnemonic):false;
    localStorage.setItem(this.key_address, address)
  }

  deleteUser() {
    UtilityService.Mnemonic = null
    UtilityService.AccNo = null
    localStorage.removeItem(this.key_acc)
    localStorage.removeItem(this.key_mnemonic)
  }

  loadUser() {
    UtilityService.Mnemonic = localStorage.getItem(this.key_mnemonic);
    UtilityService.AccNo = localStorage.getItem(this.key_acc);
  }

  async presentToast(message : any) {
    const t = await this.toast.create({
      message: message,
      duration: 3000,
      position: "bottom",
      showCloseButton: true,
      closeButtonText: "Refresh",
    });
    t.present();
    const dismiss = await t.onDidDismiss();
    if (dismiss.role === "cancel") {
      return 'cancel';
    }
  }

  goHome() {
    this.router.navigate(["/tabs"]);
  }

  showPrivacy() {
    this.router.navigate(["/privacy"]);
  }

  showTerms() {
    this.router.navigate(["/terms"]);
  }

  goInfoTab() {
    this.router.navigate(['/tabs/info'])
  }

  async checkTermsPrivacyAgreement() {
    // show terms and conditions
    const checkPolicy = localStorage.getItem(this.accept_policy_key);

    if (checkPolicy != "yes") {
      // show action sheet
      const actionSheet = await this.actionSheetController.create({
        header: "I agree to privacy policy and terms of service",
        buttons: [
          {
            text: "Yes",
            role: "destructive",
            icon: "checkmark",
            handler: () => {
              localStorage.setItem(this.accept_policy_key, "yes");
            },
          },
          {
            text: "Read Privacy Policy",
            icon: "document",
            handler: () => {
              this.showPrivacy();
            },
          },
          {
            text: "Read Terms of Service",
            icon: "albums",
            handler: () => {
              this.showTerms();
            },
          },
          {
            text: "Not now",
            icon: "close",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    }
  }
}
